import {calculateAgeAsOfToday, differenceBetweenDatesInYears, addDaysToGivenDate, differenceBetweenDatesInDays} from "../common/DateUtils";
import { regions, reEffectiveByDate, rpc1510EffectiveByDate, urlLOB } from "../constant/constants";
import autoQuoteResources from "../../../resources/translations/en-us/autoquote";

const zipCodeRegex = /(?!00[02-5]|099|213|269|34[358]|353|419|42[89]|51[789]|529|53[36]|552|5[67]8|5[78]9|621|6[348]2|6[46]3|659|69[4-9]|7[034]2|709|715|771|81[789]|8[3469]9|8[4568]8|8[6-9]6|8[68]7|9[02]9|987|1111)\d{5}/;

const updateViewModel = (setViewModel, key, value) => {
    setViewModel(prevViewModel => ({
        ...prevViewModel,
        [key]: value
    }));
};

const updateNestedViewModel = (setViewModel, objectKey, key, value) => {
    setViewModel(prevViewModel => ({
        ...prevViewModel,
        [objectKey]: {...prevViewModel[objectKey], [key]: value}
    }));
};

const ageUnder15 = (value) => {
    const age = calculateAgeAsOfToday(value, 'MM-dd-yyyy');
    return (age >= 15)? null : "under15";
};

const ageOver100 = (value) => {
    const age = calculateAgeAsOfToday(value, 'MM-dd-yyyy');
    return (age < 100) ? null : 'over100';
};

const isFeb29DateValid = (value) => {
    let feb29Valid = true;
    if(value && value?.length === 10){
        const dateValues = value?.split("-")
        if(dateValues && dateValues[0] && dateValues[1] && dateValues[2] && dateValues[0] === '02' && dateValues[1] === '29'){
            feb29Valid = (Number.parseInt(dateValues[2]) % 4 === 0);
        }
    }
    return (feb29Valid)? null : "valid";
};

const emailsShouldMatch = (value1, value2) => {
    return (value1?.toLowerCase() === value2?.toLowerCase())? null : "match";
};

const customFieldValidationsPrimaryDriverBasicInfo = (viewModel) => {
    const customValidations = {"PrimaryDriverBasicInfo.emailConfirm" : {emailsShouldMatch: value => (emailsShouldMatch(value, viewModel.email))},
        "PrimaryDriverBasicInfo.DriverBasicInfo.dateOfBirth" : {feb29Validation: value => (isFeb29DateValid(value)), under15: value => (ageUnder15(value)), over100: value => (ageOver100(value))}};
    return customValidations
};

const customFieldValidationsPrimaryDriverAdditionalInfo = (viewModel) => {
    const customValidations = {"PrimaryDriverAdditionalInfo.DriverAdditionalInfo.licensedAge" : {under15: value => (value < 15)?"under15":null, valid: value => (value > calculateAgeAsOfToday(viewModel.basicInfo?.dateOfBirth, 'MM-dd-yyyy'))?"valid":null}, "PrimaryDriverAdditionalInfo.phoneNumber" : {extnWithoutPhoneNumber: value => (value === '' && viewModel.extension && viewModel.extension !== '')?"valid":null}};
    return customValidations
};

const customFieldValidationsAdditionalDriver = (viewModel) => {
    const customValidations = {"AdditionalDriver.DriverAdditionalInfo.licensedAge" : {under15: value => (value < 15)?"under15":null, valid: value => (value > calculateAgeAsOfToday(viewModel.basicInfo?.dateOfBirth, 'MM-dd-yyyy'))?"valid":null},
        "AdditionalDriver.DriverBasicInfo.dateOfBirth" : {feb29Validation: value => (isFeb29DateValid(value)), under15: value => (ageUnder15(value))}};
    return customValidations
};

const getPrimaryDriverAgeCategory = (dateOfBirth, region) => {
    const age = calculateAgeAsOfToday(dateOfBirth, 'MM-dd-yyyy');
    let ageCategory = '';
    if(age >= 69 && age < 101 && region === regions.OREGON) ageCategory ='Senior';
    else if(age >= 55 && ((age < 69 && region === regions.OREGON) || region === regions.CALIFORNIA)) ageCategory = 'Mature';
    else if(age >= 19 && age < 55) ageCategory = 'Adult';
    else if(age >= 15 && age < 19 && region === regions.OREGON) ageCategory = 'Minor';
    return ageCategory;
};

const getANICurrentValue = (driversList) => {
    let aniCurrentValue = '';
    Object.keys(driversList).forEach(driverKey => {
            if(driversList[driverKey].isAdditionalNamedInsured){
                aniCurrentValue = driversList[driverKey].driverId;
            }
        }
    );
    return aniCurrentValue;
};

const getCurrentAgeOfVehicle = (vehicleYear, effectiveDate, offset) => {
    var vehicleBirthDate = new Date(vehicleYear, 9, 1).toISOString();  
    if(effectiveDate === undefined){
        effectiveDate = addDaysToGivenDate(new Date(), offset).toISOString();
    }
    return differenceBetweenDatesInYears(effectiveDate, vehicleBirthDate);
};

const getRentalExpenseEffectiveDate = (effectiveDate, offset) => {
    const rentalExpenseEffectiveByDate = reEffectiveByDate.toISOString();
    if(effectiveDate === undefined){
        effectiveDate = addDaysToGivenDate(new Date(), offset).toISOString();
    }
    return differenceBetweenDatesInDays(effectiveDate, rentalExpenseEffectiveByDate);
};

const isRPC1510Effective = (effectiveDate) => {
    return differenceBetweenDatesInDays(effectiveDate.toISOString(), rpc1510EffectiveByDate.toISOString()) >= 0;
};

const isValidZipCode = (zipCode) => {
    return zipCode && zipCode.length === 5 && zipCodeRegex.test(zipCode);
};

const isORZipCode = (value) => {
    const zipCode = value?.split('-')[0];
    return (zipCode > 97000 && zipCode < 97921)
};

const isCAZipCode = (value) => {
    const zipCode = value?.split('-')[0];
    return (zipCode > 90000 && zipCode < 96163)
};

const removeArrayValue = (arrayInput, value) => {
    var i = 0;
    while (i < arrayInput.length) {
        if (arrayInput[i] === value) {
            arrayInput.splice(i, 1);
        } else {
            ++i;
        }
    }
    return arrayInput;
};

const getGAStepDataLayerDetails = (sectionName, stepName) => {
    const dataLayerDetails = {
        dataLayer: {
            event: 'stepLoad',
            funnelName: 'Auto Quote',
            sectionName: sectionName,
            stepName: stepName
        }
    };
    return dataLayerDetails;
};

const getProvinceCode = (region) => {
    if(region === regions.OREGON){
        return 'OR';
    }
    else if(region === regions.CALIFORNIA){
        return 'CA';
    }
    else return '';
};

const setYearUIValues = (yrs) => {
    if(yrs === 1) {
        return yrs + " year";
    }
    else if(yrs < 1){
        return "Less than 1 year";
    }
    else if(yrs >= 9){
        return "9 years or longer";
    }
    else{
        return yrs + " years";
    }
};

const isInsuranceCompanyNameMatch = (name) => {
    return autoQuoteResources.PrimaryDriverAdditionalInfo.currentInsuranceCompany.options.find(el => el.code === name);
};

// return window.location.pathname.startsWith(`/${urlLOB.AUTO}`)
const isPersonalPropertyLOB = () => { return window.location.pathname.startsWith(`/${urlLOB.PERSONAL_PROPERTY}`); };
const isAutoLOB = () => { return window.location.pathname.startsWith(`/${urlLOB.AUTO}`); };

const isAddressPOBox = (e) => {
    const tokens = ["PO BOX", "P.O. BOX", "P.O.BOX", "P O BOX", "P.O BOX"];
    tokens.forEach(token => {
        if (e.toUpperCase().includes(token)) {
            return true;
        }
        return false;
    });
};

export {zipCodeRegex, updateViewModel, customFieldValidationsPrimaryDriverBasicInfo, customFieldValidationsPrimaryDriverAdditionalInfo,
    customFieldValidationsAdditionalDriver, getPrimaryDriverAgeCategory, getANICurrentValue, getCurrentAgeOfVehicle, isValidZipCode,
    isORZipCode, isCAZipCode, removeArrayValue, getGAStepDataLayerDetails, isFeb29DateValid, getProvinceCode, getRentalExpenseEffectiveDate,
    isRPC1510Effective, setYearUIValues, isInsuranceCompanyNameMatch, updateNestedViewModel, isPersonalPropertyLOB, isAutoLOB, isAddressPOBox}
