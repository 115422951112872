import {featureFlagNames} from "../constant/constants";

const featureFlags = [
    featureFlagNames.EFFECTIVE_DATE_OFFSET,
    featureFlagNames.FBCAPI,
    featureFlagNames.MAINTENANCE,
    featureFlagNames.MEDIA_ALPHA_TAG,
    featureFlagNames.MEDIA_ALPHA_TRACKING,
    featureFlagNames.PREFILL,
    featureFlagNames.PURCHASE_POLICY,
    featureFlagNames.RCTOKEN,
    featureFlagNames.RECAPTCHA,
    featureFlagNames.TEMP_ZIP_CODE_BYPASS,
    featureFlagNames.TEMP_OREGON_SHUTDOWN,
    featureFlagNames.TEMP_SALES_INSTRUCTION,
    featureFlagNames.TEMP_VEHICLE_MAKES,
    featureFlagNames.TEMP_HOME_INSURANCE
];

const isEnabled = (featureFlag) =>{
    return featureFlag?.treatment === 'on'
};

const evaluateFlag = (featureFlag) =>{
    return featureFlag?.treatment
};

const evaluateFlagTreatment = (featureFlag) =>{
    if(featureFlag.config){
        return JSON.parse(featureFlag.config)
    } else {
        return ''
    }
};

const evaluateFlagConfig = (featureFlag) =>{
    if(featureFlag?.config){
        return JSON.parse(featureFlag.config)?.value
    }
    return ''
};

export {isEnabled, evaluateFlag, evaluateFlagTreatment, evaluateFlagConfig, featureFlags}