import { v4 as uuidv4 } from 'uuid';
const {policyCenterURL, vinURL, launchDarklyID, reCaptchaKey, gaTrackingKey, gtmTrackingKey, utilityServiceURL, googleAutoCompleteKey, environment, websiteURL, esbURL, pcbcV4ApiHostUrl} = window.runtimeConfiguration;

const autoQuoteConfigurations = {
    POLICYCENTER: {
        SERVICE_URI: {
            SUBMISSION_SERVICE: (environment && environment === 'local')? "/service/rest/us/auto/quote" : "/auto-insurance/service/quotes",
            AVAILABILITY_SERVICE: (environment && environment === 'local')? "/service/rest/general/productavailability" : "/auto-insurance/service/general/productavailabilities",
            PREFILL_SERVICE: (environment && environment === 'local')? "/service/rest/general/prefill" : "/auto-insurance/service/general/prefill"
        }
    },
    RECAPTCHA: {
        SERVICE_HOST: (environment && environment === 'local')? esbURL : policyCenterURL,
        SERVICE_URI: {
            VERIFICATION_SERVICE: (environment && environment === 'local')? "/usweb/rest/business-recaptcha-system/v1/verify" : "/auto-insurance/service/captcha/verify"
        }
    },
    GEOWATERFIRE: {
        SERVICE_HOST: (environment && environment === 'local') ? pcbcV4ApiHostUrl : policyCenterURL,
        SERVICE_URI: {
            GEOCODE_VERIFICATION_SERVICE: (environment && environment === 'local') ? "/rest/business-geocode-process/v1/address" : "/auto-insurance/service/geocode",
            WILDFIRE_VERIFICATION_SERVICE: (environment && environment === 'local') ? "/rest/policy-wildfirerating-process/v1/wildfire-rating-latlong" : "/auto-insurance/service/wildfire",
            WATERRISK_VERIFICATION_SERVICE: (environment && environment === 'local') ? "/rest/policy-waterriskrating-process/v1/hazard-rating" : "/auto-insurance/service/waterrisk"
        }
    }
};

const getPolicyCenterConfig = () => {
    return autoQuoteConfigurations.POLICYCENTER;
};

const getPolicyCenterURL = () => {
    return policyCenterURL;
};

const getWebsiteHost = () => {
    return (environment && environment === 'local')? websiteURL : policyCenterURL;
};

const getVinURL = () => {
    return (environment && environment === 'local')?vinURL:`${getPolicyCenterURL()}/auto-insurance/service/vin/`;
};

const getRecaptchaApiKey = () => {
    return reCaptchaKey;
};

const getGATrackingKey = () => {
    return gaTrackingKey;
};

const getGTMTrackingKey = () => {
    return gtmTrackingKey;
};

const getBackendServerConfig = () => {
    return utilityServiceURL;
};

const getGoogleAutocompleteKey = () => {
    return googleAutoCompleteKey;
};

const getReCaptchaConfig = () => {
    return autoQuoteConfigurations.RECAPTCHA;
};

const isLocalEnvironmentMode = () => {
    return (environment && environment === 'local')
};

const getMediaAlphaToken = (params) => {
    return params.get('clickid');
};

const getSplitIOAPIKey = () => {
    return launchDarklyID;
};

const getSplitIOConfig = (params) => {
    return {core: {authorizationKey: getSplitIOAPIKey(), key: 'key'}}
};

const getSplitIOAttributes = (params) => {
    const wmichcvalue = params.get('wmichc');
    return {wmichc : wmichcvalue}
};

const getGeoWaterFireConfig = () => {
    return autoQuoteConfigurations.GEOWATERFIRE;
};

export {getPolicyCenterURL, getVinURL, getPolicyCenterConfig, getRecaptchaApiKey, getGATrackingKey, getGTMTrackingKey,
    getBackendServerConfig, getGoogleAutocompleteKey, getWebsiteHost, getReCaptchaConfig, isLocalEnvironmentMode,
    getMediaAlphaToken, getSplitIOAPIKey, getSplitIOConfig, getSplitIOAttributes, getGeoWaterFireConfig};