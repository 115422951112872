const BASE_US_HOME_URL = "https://www.wawanesa.com/us/";

export const regions = {
    OREGON: 'oregon',
    CALIFORNIA: 'california'
}

export const websiteURL = {
    OREGON: `${BASE_US_HOME_URL}oregon/`,
    CALIFORNIA: `${BASE_US_HOME_URL}california/`,
    US_HOME: BASE_US_HOME_URL,
    RENTERS_QNB: 'https://renters.wawanesa.com/'
}

export const defaultZipCodes = {
    OREGON: '97210',
    CALIFORNIA: '90210'
}

export const SupportedRegions = ["oregon", "california", "californiaAlt"];

export const rpcEffectiveByDate = new Date() >= new Date(2021,7,1);

export const racEffectiveByDate = new Date() >= new Date(2021, 8, 16);

export const reEffectiveByDate = new Date(2021,9,1);

export const rpc1510EffectiveByDate = new Date(2022,3,1);

export const rpc1578EffectiveByDate = (effectiveDateOffset) => {
    const RPC1578EffectiveDate = new Date('2022-06-01');
    let effectiveDate = new Date();
    effectiveDate.setDate(effectiveDate.getDate() + Number(effectiveDateOffset));
    return effectiveDate >= RPC1578EffectiveDate;
}

export const rpc1759EffectByDate = (effectiveDateOffset) => {
    const RPC1759EffectiveDate = new Date('2024-11-01');
    let effectiveDate = new Date();
    effectiveDate.setDate(effectiveDate.getDate() + Number(effectiveDateOffset));
    return effectiveDate >= RPC1759EffectiveDate;
}

export const rpc1855EffectiveByDate = (effectiveDateOffset) => {
    const RPC1855EffectiveDate = new Date('2025-01-01');
    let effectiveDate = new Date();
    effectiveDate.setDate(effectiveDate.getDate() + Number(effectiveDateOffset));
    return effectiveDate >= RPC1855EffectiveDate;
}

export const currentlyInsured = 1;

export const notCurrentlyInsured = 0;

export const homeOwner = 1;

export const notHomeOwner = 0;

export const MAX_RETRY = 3;

export const delayTime = 3000;

export const fbPixelID = '250248612149992';

export const fbAccessToken = 'EAAFOkymUhEwBAJwMy9W0LYrZBcSeGPF6qkxMV4UXxErnvbzV7Jdh8pgbv4z4agR4bObf9i5KWh1ey2ZCe3iX9plwapZBbCyfkjvEiJzGQ6ucQy7HTj0cam0QSBNOT2ZA0eTG2aIehLynnZB6c1RWyKIHgCB5chUjlLXbNobvIiNZCJy32kPBaQ8LZCcNyVNwSMZD';

export const sessionIdleTime = 1800000;

export const featureFlagNames = {
    EFFECTIVE_DATE_OFFSET: "operational-effectivedateoffset",
    FBCAPI: "operational-fbcapi",
    MAINTENANCE: "operational-maintenance",
    MEDIA_ALPHA_TAG: "operational-mediaalphatag",
    MEDIA_ALPHA_TRACKING: "operational-mediaalphatracking",
    PREFILL: "operational-prefill",
    PURCHASE_POLICY: "operational-purchasepolicy",
    RCTOKEN: "operational-rctoken",
    RECAPTCHA: "operational-recaptcha",
    TEMP_ZIP_CODE_BYPASS: "temp-zipcodebypass",
    TEMP_OREGON_SHUTDOWN: "temp-oregonshutdown",
    TEMP_SALES_INSTRUCTION: "temp-salesinstruction",
    TEMP_VEHICLE_MAKES: "temp-vehiclemakes",
    TEMP_HOME_INSURANCE: "temp-homeinsurance"
}

export const urlLOB = {
    AUTO: "auto-insurance",
    PERSONAL_PROPERTY: "home-insurance"
}

export const lobType = {
    AUTO: "auto",
    PERSONAL_PROPERTY: "personal-property"
}

export const insuranceType = {
    AUTO: "auto",
    HOME: "home",
    RENTERS: "renters",
    CONDO: "condo"
}