import { getProvinceCode } from "../page/PageUtils";

const getInitialDataForAddressInfo = (region) => {
    return { 
        address: {
            streetAddress: '',
            unitNumber : '',
            poBox : '',
            city : '',
            province : getProvinceCode(region),
            zipCode : ''
        }
    }
};

const getInitialDataForInsuranceType = () => {
    return {propertyInsType: '', transactionData: {}};
};

const getInitialDataForHomeDetail = () => {
    return {
        primaryResidence: '',
        builtBeforeYear: '',
        plumbingRelatedClaim: '',
        locatedCloseToCommBldg: '',
        transactionData: {}
    };
};

const getInitialDataForStartQuote = () => {
    return {zipCode: '', propertyInsType: 'home', transactionData: {}};
};

export {getInitialDataForAddressInfo, getInitialDataForInsuranceType, getInitialDataForHomeDetail, getInitialDataForStartQuote}